import React from "react";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  color: #fff;
  z-index: 0 !important;
  position: relative;
`;

const TituloContainer = styled.div`
`;

const Descripcion = styled.div`
  width: 60%;
  margin: 10px;
  padding: 20px;
  border: 1px solid #18aea9;
  border-radius: 20px;
  background-color: rgba(24, 174, 169, 0.18);
  @media (max-width: 768px) {
    width: 100%;
  }
  @media (max-width: 1024px) {
    width: 80%;

  }
`;

const TitlePage = styled.h1`
  font-size: calc(24px + (48 - 24) * ((100vw - 300px) / (1600 - 300)));
  font-weight: 400;
  text-align: center;
  margin: 0;
  padding: 10px 0;
`;

const Description = styled.p`

  font-size: calc(14px + (24 - 14) * ((100vw - 300px) / (1600 - 300)));
  font-weight: 400;
  text-align: justify;
  margin: 0;
  padding: 30px;
	@media (max-width: 768px) {
		padding: 10px;
  }
	@media (max-width: 480px) {
		padding: 0;
  }
`;

const BoxSombra = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.48);
  z-index: 1;
  padding: 20px 0;
  width: 100%;
`;

const Nosotros = () => {
	return (
		<Container id={"nosotros"}>
			<BoxSombra>
				<TituloContainer>
					<TitlePage>¿Quiénes somos?</TitlePage>
				</TituloContainer>
				<Descripcion>
					<Description>
						Somos una empresa de servicios profesionales en el sector de las nuevas tecnologías de la información:
						Nuestro firme compromiso es apoyándote y desarrollar tus proyectos desde el comienzo hasta mas allá de la entrega final,
						entregándote un software robusto y confiable que le de ese pequeño gran impulso a tu negocio.

					</Description>
				</Descripcion>
			</BoxSombra>
		</Container>
	);
}

export default Nosotros;
