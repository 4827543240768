import React from "react";
import styled from "styled-components";
import { Link } from "gatsby"

const ContainerInicio = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  background-color: #000;
  color: #fff;
`;

const Titulo = styled.h1`
  font-size: calc(44px + (112 - 44) * ((100vw - 300px) / (1600 - 300)));  font-weight: 700;
  text-align: center;
  margin: 0;
  padding: 0;
`;

const Subtitulo = styled.h2`
  font-size: calc(24px + (88 - 24) * ((100vw - 300px) / (1600 - 300)));
  font-weight: 400;
  text-align: center;
  margin: 0;
  padding: 0;
`;

const Descripcion = styled.div`
	font-size: calc(16px + (28 - 16) * ((100vw - 300px) / (1600 - 300)));
	font-weight: 400;
	text-align: center;
	margin: 0;
	padding: 0;
	width: 60%;
	@media (max-width: 768px) {
		width: 100%;
  }
	@media (max-width: 1024px) {
		width: 80%;
		
  }
`;

  const BoxSombra = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.48);
  position: absolute;
  z-index: 1;
		padding: 20px;
`;

const ContactButton = styled(Link)`
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #000;
	color: #fff;
	text-decoration: none;
	padding: 1rem 2rem;
	border-radius: 25px;
	font-size: 1.2rem;
`;

const Inicio = () => {
	return (
		<ContainerInicio>
			<BoxSombra>

			<Titulo>SCRIPTDOG</Titulo>
			<Subtitulo>SOLUTIONS</Subtitulo>

			<Descripcion>
				<p>Acompañándote en cada etapa de tu proyecto, somos una empresa especialista
					en desarrollo integral de software personalizado para tus necesidades.</p>
			</Descripcion>

			<ContactButton
				to="#contacto"
			>
				CONTACTO
			</ContactButton>
			</BoxSombra>

		</ContainerInicio>
	)
}

export default Inicio
