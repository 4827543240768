import React from "react";
import styled from "styled-components";
import {FaEnvelope} from "@react-icons/all-files/fa/FaEnvelope";
import {FaPhone} from "@react-icons/all-files/fa/FaPhone";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  height: 100vh;
  width: 100%;
  color: #fff;
  z-index: 0 !important;
  position: relative;
`;

const TituloContainer = styled.div`
`;

const Descripcion = styled.div`
  //width: 40%;
  margin: 10px;
  padding: 20px;
  border: 1px solid #18aea9;
  border-radius: 20px;
  background-color: rgba(24, 174, 169, 0.18);
  @media (max-width: 768px) {
    width: 100%;
  }
  @media (max-width: 1024px) {
    width: 80%;

  }
`;

const TitlePage = styled.h1`
  font-size: calc(24px + (48 - 24) * ((100vw - 300px) / (1600 - 300)));
  font-weight: 400;
  text-align: center;
  margin: 0;
  padding: 10px 0;
`;

const Description = styled.div`

  font-size: calc(14px + (24 - 14) * ((100vw - 300px) / (1600 - 300)));
  font-weight: 400;
  //text-align: justify;
  margin: 30px;
  padding: 0;
  flex-direction: row;
  display: flex;
  align-items: center;
	@media (max-width: 480px) {
		padding: 0;
		margin: 10px 0;
  }
`;

const NoStyleLink = styled.a`
  text-decoration: none;
  color: #fff;
  font-size: calc(14px + (38 - 14) * ((100vw - 300px) / (1600 - 300)));
	flex: 1	;
	align-self: center;
`;


const BoxSombra = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.48);
  z-index: 1;
  padding: 20px 0;
  width: 100%;
  height: 70vh;
`;

const ServiceContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  //justify-content: center;s
  align-items: center;
  margin: 10px;
  padding: 10px;
  //border: 1px solid #18aea9;
  //border-radius: 20px;
  //background-color: rgba(24, 174, 169, 0.18);
  @media (max-width: 480px) {
  }
  @media (max-width: 1024px) {
    //width: 80%;
    flex-direction: column;

  }
`;

const CopyRight = styled.div`

  font-size: calc(14px + (24 - 14) * ((100vw - 300px) / (1600 - 300)));
  font-weight: 400;
  text-align: center;
  margin: 0;
  padding: 10px 0;
  flex-direction: column;
  display: flex;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.48);
  width: 100%;
`;

const DataContacto = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;	
	margin: 0  20px;
	//padding: 10px;
	@media (max-width: 480px) {
		margin: 0 10px;
  }
`;

const DivIcon = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	//justify-content: center;s
	//align-items: center;
	//margin: 10px;
	//padding: 10px;
	//border: 1px solid #18aea9;
`;

const Contacto = () => {
	return (
		<Container id={"contacto"}>
			<BoxSombra>
				<TituloContainer>
					<TitlePage>Contacto</TitlePage>
				</TituloContainer>
				<ServiceContainer>
					<Descripcion>
						<Description>
							{/*	icon correo*/}
							<DivIcon>

							<FaEnvelope size={26}/>
							</DivIcon>
							{/*	correo*/}
							<DataContacto>

							<NoStyleLink
								href="mailto:comercial@scriptdog.cl"
							>
								comercial@scriptdog.cl
							</NoStyleLink>
							</DataContacto>
						</Description>
						<Description>
							{/*	icon phone*/}
							<FaPhone size={26}/>
							{/*	phone*/}
							<DataContacto>

							<NoStyleLink href="tel:+56977216059"
							>
								+569 772 160 59
							</NoStyleLink>
							</DataContacto>
							</Description>
					</Descripcion>
				</ServiceContainer>


			</BoxSombra>
			<CopyRight>
				© 2022 Script Dog Solutions
			</CopyRight>
		</Container>
	);
}

export default Contacto;
