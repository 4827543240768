import React from "react";
import styled from "styled-components";
import {graphql, useStaticQuery} from "gatsby";
import Img from "gatsby-image";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  color: #fff;
  z-index: 0 !important;
  position: relative;
`;

const TitleContainer = styled.div`
  //align-self: center !important;
  //padding-left: 10%;
`;

const Descripcion = styled.div`
  //align-self: flex-end;
  //width: 60%;
  //padding-right: 10%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;

  flex-wrap: wrap;


`;


const BoxSombra = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.48);
  z-index: 1;
  padding: 20px 0;
  width: 100%;
  @media (max-width: 480px) {
    margin:  0;
    padding: 20px 0;
  }
	
`;

const ServiceContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 300px;
  height: 300px;
  //justify-content: center;s
  align-items: center;
  margin: 10px;
  padding: 10px;
  border: 1px solid #18aea9;
  border-radius: 20px;
  background-color: rgba(24, 174, 169, 0.18);
  @media (max-width: 480px) {
    width: 200px;
    height: 200px;
  }
  @media (max-width: 1024px) {
    //width: 80%;

  }
`;

const Title = styled.h2`
  font-size: calc(16px + (32 - 16) * ((100vw - 300px) / (1600 - 300)));
  font-weight: 400;
  text-align: center;
  margin: 0;
  padding: 0;
`;

const TitlePage = styled.h1`
	font-size: calc(24px + (48 - 24) * ((100vw - 300px) / (1600 - 300)));
	font-weight: 400;
	text-align: center;
	margin: 20px 0;
	padding: 0;
`;

const Image = styled(Img)`
	background-color: transparent;
  width: 200px;
`;

const Servicios = () => {
	const images = useStaticQuery(graphql`
		query {
			consultoria: file(name: { eq: "consultoriaWeb" }, extension: { eq: "png" }) {
				childImageSharp {
					fluid(maxWidth: 300, maxHeight: 300, quality: 100, fit: COVER) {
						...GatsbyImageSharpFluid
					}
				}
			}
			app: file(name: { eq: "app" }, extension: { eq: "png" }) {
				childImageSharp {
					fluid(maxWidth: 300, maxHeight: 300, quality: 100, fit: COVER) {
						...GatsbyImageSharpFluid
					}
				}
			}
			streaming: file(name: { eq: "streaming" }, extension: { eq: "png" }) {
				childImageSharp {
					fluid(maxWidth: 300, maxHeight: 300, quality: 100, fit: COVER) {
						...GatsbyImageSharpFluid
					}
				}
			}	
			web: file(name: { eq: "web" }, extension: { eq: "png" }) {
				childImageSharp {
					fluid(maxWidth: 300, maxHeight: 300, quality: 100, fit: COVER) {
						...GatsbyImageSharpFluid
					}
				}
			}	
		}
	`)

	return (
		<Container id={"servicios"}>
			{/*<h1>Quienes Somos</h1>*/}
			{/*Somos una empresa de servicios profesionales en el sector de las nuevas tecnologías de la información, comunicación y transformación digital.*/}
			{/*Seremos la mejor empresa que puedas tener como socio tecnológico para tus desafíos.*/}
			{/*Desarrollaremos tus proyectos apoyándote y te acompañaremos en todo el proceso de creación desde el comienzo hasta la entrega final.*/}
			{/*Nuestro compromiso es lograr que tus objetivos se cumplan.*/}
			<BoxSombra>

				<TitleContainer>
					<TitlePage>Servicios</TitlePage>
				</TitleContainer>
				<Descripcion>
					<ServiceContainer>
						<Title>Consultoria</Title>
						<Image fluid={images.consultoria.childImageSharp.fluid}/>

					</ServiceContainer>
					<ServiceContainer>
						<Title>Desarrollo de Apps</Title>
						<Image fluid={images.app.childImageSharp.fluid} alt="logo"/>
					</ServiceContainer>
					<ServiceContainer>
						<Title>Streaming</Title>
						<Image fluid={images.streaming.childImageSharp.fluid} alt="logo"/>
					</ServiceContainer>
					<ServiceContainer>
						<Title>Desarrollo Web</Title>
						<Image fluid={images.web.childImageSharp.fluid} alt="logo"/>
					</ServiceContainer>

				</Descripcion>
			</BoxSombra>

		</Container>
	);
}

export default Servicios;