import * as React from "react"
import Navbar from "../Navbar/Navbar";
import "../styles.css"
import Inicio from "../Inicio/Inicio";
import Nosotros from "../Nosotros/Nosotros";
import Img from "gatsby-image";
import {graphql, useStaticQuery} from "gatsby";
import Servicios from "../Servicios/Servicios";
import Contacto from "../Contacto/Contacto";

const pageStyles = {
  color: "#232129",
  fontFamily: "Roboto, sans-serif, serif",
  height: "100%",
}
const BackgroundImage = {
  position: "fixed",
  top: "0",
  left: "0",
  width: "100%",
  height: "100vh",
  zIndex: "0",

}

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      file(name: { eq: "Fondo1" }, extension: { eq: "png" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <>
      <Navbar />
    <main style={pageStyles}>
      <Img fluid={data.file.childImageSharp.fluid} alt="Fondo" style={BackgroundImage}/>

      <Inicio />
      <Nosotros />
      <Servicios />
      <Contacto />
    </main>

    </>

  )
}

export default IndexPage

export const Head = () =>
  (<>
      <title>Scriptdog</title>
      <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
      <meta name="description" content="Scriptdog"/>
      <meta name="keywords" content="Streaming, Desarrollo, Web, App, Ingenieria, Consultoria"/>
      <meta name="author" content="Scriptdog"/>
    </>
)
